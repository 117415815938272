<template>
  <v-card>
    <v-card-title style="display: block" class="pb-1 pr-8">
      {{ title }}
    </v-card-title>
    <v-card-text class="form_container">
      <v-container fluid>
        <items-panels
          v-if="originalNotaNF && !isLoading"
          :canDelete="canDelete"
          :items="notaNF.itens"
          :projectsByYear="projetosPorAno"
          @input="updateValue(notaNF, 'itens', $event)"
          @loadProjectsFromYear="getProjectsByYear($event)"
          @update:valid="updateValid('itens', $event)"
        >
          <input-v
            v-for="(field, key) in fields"
            class="pl-4"
            :colSize="field.colSize"
            :editable="field.editable"
            :key="key"
            :label="field"
            :valid="valid.get(field.key)"
            :value="notaNF[field.key]"
            :opts="opts"
            @input="updateValue(notaNF, field.key, $event)"
            @update:valid="updateValid(field.key, $event)"
          ></input-v>
        </items-panels>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="px-3" color="secondary" depressed large @click="onBack()">
          <template v-if="hasChanges">
            <v-icon class="mr-1">mdi-alpha-x-box</v-icon>
            Cancelar
          </template>
          <template v-else>
            <v-icon class="mr-1">mdi-arrow-left</v-icon>
            Voltar
          </template>
        </v-btn>

        <v-btn
          v-if="hasChanges"
          class="px-3"
          color="primary"
          depressed
          dense
          large
          @click="onSave"
        >
          <template v-if="isCreate">
            <v-icon class="mr-1">mdi-plus-box</v-icon>
            Criar
          </template>
          <template v-else>
            <v-icon class="mr-1">mdi-content-save</v-icon>
            Salvar
          </template>
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { cloneDeep, isEmpty, uniq } from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import getDifferences from "@/helpers/getDifferences";

export default {
  components: {
    "input-v": () => import("@/components/input-v.vue"),
    "items-panels": () => import("./equipamentos-e-softwares.form/items.panels.vue"),
  },
  computed: {
    ...mapGetters(["clientId"]),
    canDelete: function () {
      return this.userHasAccessToAction('delete');
    },
    hasChanges: function () {
      const diff = getDifferences(this.originalNotaNF, this.notaNF);
      return !isEmpty(diff);
    },
    isCreate: function () {
      return this.$route.meta.mode === "create";
    },
    fields: function () {
      return [
        {
          key: "nro_documento",
          name: "Nº Documento",
          type: this.$fieldTypes.TEXT,
          colSize: 6,
          rules: [{ rule: "required" }],
        },
        {
          key: "terceiroId",
          name: "Fornecedor",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "terceiros", key: "id", name: "razao_social" },
          colSize: 6,
          rules: [{ rule: "required" }],
        },
        {
          key: "valor_nf",
          name: "Valor",
          type: this.$fieldTypes.MONEY,
          colSize: 4,
          rules: [{ rule: "required" }, { rule: "min", params: { min: 0.01 }}],
        },
        {
          key: "data",
          name: "Emissão",
          type: this.$fieldTypes.DATE,
          colSize: 4,
          rules: [{ rule: "required" }],
        },
      ];
    },
    nfId: function () {
      return this.$route.params.nfId;
    },
    resource: function () {
      return this.apiResource(`/v1/fiscal/nfdepreciacao/${this.clientId}`);
    },
    resourceProjetos: function () {
      return this.apiResource(`v1/projetos/${this.clientId}/selecao`);
    },
    title: function () {
      return `${this.isCreate ? 'Cadastro' : 'Edição'} de Equipamentos/Softwares`;
    },
  },
  created: function () {
    this.apiResource(`v1/fiscal/terceiro/${this.clientId}/material`)
      .get()
      .then((response) => (this.opts.terceiros = response));
    this.doLoad();
  },
  data: function () {
    const defaultNotaNF = {
      nro_documento: null,
      terceiroId: null,
      valor_nf: 0,
      data: null,
      itens: [],
    };
    // Inicializa todos os anos possiveis para o Vue detectar a alteração nos arrays
    // 2020 é o ano de ínicio das operações do Teros
    const size = moment().get('years') - 2020 + 1;
    const projetosPorAno = new Array(size).fill(0).reduce((prev, _, index) => ({ ...prev, [2020 + index]: [] }), {});

    return {
      defaultNotaNF,
      isLoading: false,
      notaNF: cloneDeep(defaultNotaNF),
      originalNotaNF: cloneDeep(defaultNotaNF),
      opts: {
        terceiros: [],
      },
      projetosPorAno,
      valid: new Map(),
    };
  },
  methods: {
    checkFormValidation: function () {
      return Array.from(this.valid).reduce((acc, [key, validFn]) => {
        if (!validFn()) {
          return [...acc, key];
        }

        return acc;
      }, []);
    },
    doLoad: async function () {
      if (!this.nfId || this.isCreate) {
        return;
      }

      try {
        this.isLoading = true;
        const response = await this.resource.get(this.nfId);

        if (response.error) {
          throw response.error;
        }

        const anos = response.itens
          ?.reduce((acc, { depreciacoes }) => {
            if (!Array.isArray(depreciacoes) || depreciacoes.length === 0) {
              return acc;
            }

            const anos = depreciacoes
              .map(({ competencia }) => competencia.substring(0, 4))
              .filter((ano) => ano.length === 4);

            return uniq([ ...acc, ...anos]);
          }, []);
        
        if (anos) {
          anos.forEach((ano) => this.getProjectsByYear(ano));
        }

        this.notaNF = cloneDeep(response);
        this.originalNotaNF = cloneDeep(response);
      } catch (e) {
        this.notaNF = cloneDeep(this.defaultNotaNF);
        this.originalNotaNF = cloneDeep(this.defaultNotaNF);
      } finally {
        this.isLoading = false;
      }
    },
    getProjectsByYear: function (ano) {
      if (!ano || this.projetosPorAno[ano]?.length > 0) {
        return;
      }

      const query = `ano=${ano}`;
      this.resourceProjetos.get({ query }).then(({ error, projetos }) => {
        if (error) {
          return;
        }

        this.projetosPorAno[ano] = projetos.sort(({ titulo: a = '' }, { titulo: b = '' }) => a.localeCompare(b));
      });
    },
    onBack: function () {
      this.$router.push({ name: "listagem-dispendio-equipamentos-softwares" });
    },
    onSave: function () {
      const invalidFields = this.checkFormValidation();
      if (!invalidFields.length) {
        const id = this.isCreate ? undefined : this.nfId;
        const body = cloneDeep(this.notaNF);
        body.itens.forEach((item) => {
          item.periodo_depreciacao = parseInt(item.periodo_depreciacao, 10);
        });
        this.resource.save(body, id).then((response) => {
          if (!response.error) {
            if (this.isCreate) {
              this.$router.push({ name: "edicao-dispendio-equipamentos-softwares", params: { nfId: response.id } });
            } else {
              this.$router.go(0);
            }
          }
        });
      }
    },
    updateValue: function (target, key, value) {
      if (key === "valor_deprec") {
        return;
      }

      if (key in target) {
        target[key] = value;
      }
    },
    updateValid: function (key, value) {
      this.valid.set(key, value);
    },
  },
  watch: {
    clientId: function () {
      this.doLoad();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__text {
  position: relative;
}

::v-deep {
  .theme--light.v-sheet {
    background-color: transparent;
    border-color: transparent;
  }

  .v-card__actions {
    padding-right: 24px;
    padding-left: 24px;
    position: static;

    .v-btn {
      position: sticky;
      bottom: 16px;
      z-index: 6;
    }
  }
}
</style>
